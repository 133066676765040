<template>
<b-form @submit.prevent>    
  <b-row>
    <b-col cols="6">
      <b-card-code title="Affreteur">
        <b-row>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Nom"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                TONI Momo
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0"
              label="Email"
              label-for="hi-email"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                labayifa@gmail.com
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
              label="Téléphone"
              class="p-0 m-0"
              label-for="hi-number"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                +22964644775
              </b-input-group>
            </b-form-group>
          </b-col>
         
         
        </b-row>
      </b-card-code>

    </b-col>
    <b-col cols="6">
      <b-card-code title="Moyen de transport">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Catégorie"
              class="pt-0 mt-0"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge ">
                CAMION CITERNE
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Type"
              label-for="hi-email"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                CITERNE POUR PULVERULENTS
              </b-input-group>
            </b-form-group>
          </b-col>
         
         
        </b-row>

       
      </b-card-code>

    </b-col>

  </b-row>
  <b-row>
    <b-col cols="6">
      <b-card-code title="Itinéraire">
        <b-row>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Départ"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                KPASSE ( OUIDAH )
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0"
              label="Dte départ"
              label-for="hi-email"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                26/02/2021, 12:00
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
              label="Destination"
              class="p-0 m-0"
              label-for="hi-number"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                FIFADJI ( COTONOU )
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
              label="Dte arrivée	"
              class="p-0 m-0"
              label-for="hi-number"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                28/02/2021, 12:00
              </b-input-group>
            </b-form-group>
          </b-col>
         
         
        </b-row>
      </b-card-code>

    </b-col>
    <b-col cols="6">
      <b-card-code title="Marchandise">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Catégorie"
              class="pt-0 mt-0"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge ">
                MARCHANDISES CONTENEURISEES
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Type"
              label-for="hi-email"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                CONTENEURS 10'
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Poids"
              label-for="hi-email"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                1,380.0 (KG)
              </b-input-group>
            </b-form-group>
          </b-col>
         
         
        </b-row>

       
      </b-card-code>

    </b-col>

  </b-row>

    <b-card-code title="Modifier un devis">
          <b-row>
            <b-col cols="6">
                <v-select
                  id="pays"
                  v-model="selected"
                  label="title"
                  :options="option"
                />
            </b-col>
            <b-col cols="6">
              <b-form-input
                id="mc-company"
                placeholder="Choix du transporteur"
              />
            </b-col>
            <b-col cols="6 my-2">
              <b-form-group>
              <flat-pickr
                v-model="humanDate"
                class="form-control"
                placeholder="Proposer la date de chargement"
                :config="{ altInput: true,altFormat: 'F j, Y', dateFormat: 'Y-m-d',}"
              />
            </b-form-group>
            </b-col>
            <b-col cols="6 my-2">
              <b-form-group>
              <flat-pickr
                v-model="humanDate"
                class="form-control"
                placeholder="Proposer la date de livraison"
                :config="{ altInput: true,altFormat: 'F j, Y', dateFormat: 'Y-m-d',}"
              />
            </b-form-group>
            </b-col>
          <b-col
            lg="6"
            class="mb-1"
          >
            <!-- dropdown in right side -->
            <b-input-group>
              <b-input-group-prepend>
                <b-dropdown
                  text="F CFA"
                  variant="outline-primary"
                >
                  <b-dropdown-item>F CFA </b-dropdown-item>
                  <b-dropdown-item>$</b-dropdown-item>
                  <!-- <b-dropdown-divider /> -->
                  <!-- <b-dropdown-item>Separated link</b-dropdown-item> -->
                </b-dropdown>
              </b-input-group-prepend>
              <b-form-input placeholder="34000000" />
            </b-input-group>
          </b-col>
          

          
        <!-- submit and reset -->
        <b-col
          md="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class=" float-right"
          >
            Enregistrer
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="secondary"
            class="mr-1 float-right"
          >
            Rafraichir
          </b-button>
        </b-col>

        
          </b-row>


    <template #code>
      {{ codeBasic }}
    </template>
    </b-card-code>
    

</b-form>

</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code'

import {
  BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BInputGroup, BInputGroupPrepend, BRow, BCol,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { codeHorizontalIcon } from './code'
import { codeBasic } from './dcode'


export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    vSelect,
    BButton,
    flatPickr,

    BDropdown,
    BDropdownItem,
    BDropdownDivider,

  },
  directives: {
    Ripple,

  },
  data() {
    return {
      codeHorizontalIcon,
      
      date: null,
      dateDefault: null,
      timePicker: null,
      dateNtim: null,
      multiDate: null,
      rangeDate: null,
      humanDate: null,
      disableDate: null,
      inlineDate: null,
      codeBasic,

      selected: { title: 'Chox du camion' },
      option: [{ title: 'Chox du camion' }, { title: 'RB 4526 (Chassis: 12345678)' }, { title: 'RB 4567 (Chassis: 12345678)' }, { title: 'RB 4896 (Chassis: 12345678)' }],

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
